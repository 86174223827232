/*core ui*/
body,
html {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  height: 100%;
  /* overflow-x: hidden; */
}
a {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
main {
  position: relative;
  z-index: 1;
}

.MuiToolbar-root {
  padding: 0px !important;
}
section {
  position: relative;
}
/* section.i .MuiTypography-root {
  color: white !important;
} */
pre {
  background: #f1f1f1;
}
.sticky-inner-wrapper {
  z-index: 999;
}
/*/core ui*/

/*navigation*/
.nav a,
.footer a {
  cursor: pointer;
  text-decoration: none;
}
.nav-link {
  margin-left: 32px;
  font-size: 20px;
}
.nav-link:hover {
  text-decoration: underline;
}
.footer {
  padding: 40px 0;
}
.social-nav {
  padding: 15px 0;
}
.social-nav .social svg {
  font-size: 20px;
  margin-left: 15px;
}
.footer .social svg {
  font-size: 30px;
  margin-left: 15px;
}
/*/navigation*/

/*components*/
.bg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
}
#k-img {
  height: 5em;
  margin: auto 7px;
}
.logo {
  align-items: center;
  display: flex;
  text-decoration: none;
}
.logo-text {
  font-size: 48px;
  font-weight: 400;
  font-family: Ubuntu !important;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 7px;
  text-decoration: none;
}
.logo span:nth-child(2) {
  position: relative;
  left: -6px;
}
.Typist {
  display: inline;
}
/*/components*/

/*embeds*/
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.lty-playbtn {
  background: #ffffff24 !important;
  border: 4px solid white !important;
  width: 70px !important;
  height: 70px !important;
  border-radius: 50% !important;
  opacity: 0 !important;
}
.yt-lite > .lty-playbtn:before {
  content: "";
  border-style: solid;
  border-width: 12px 0 12px 20px;
  border-color: transparent transparent transparent #fff !important;
}
.yt-lite::before {
  background: #00000080 !important;
  opacity: 0.2;
  height: 100% !important;
}
.yt-lite:hover::before {
  opacity: 0;
}
.yt-lite:hover .lty-playbtn {
  opacity: 1 !important;
}
.yt-lite.lyt-activated:hover .lty-playbtn {
  opacity: 0 !important;
}
/*/embeds*/
